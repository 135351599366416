
import videojs from 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';
// Styles
import 'video.js/dist/video-js.min.css';
import 'videojs-contrib-ads/dist/videojs-contrib-ads.css';
import 'videojs-ima/src/css/videojs.ima.css';

import WhisperAPI from '@/utils/whisper/whisperAPI';

export default {
  name: 'VideoAd',
  props: {
    width: {
      type: [String, Number]
    },
    height: {
      type: [String, Number]
    },
    isPopup: Boolean,
    videoItem: Object,
    videoId: String,
  },
  data() {
    return {
      loadingData: false,
      videoElement: null,
      videoOptions: { autoplay: this.$route.path.startsWith('/videos/') ? false : true, quality: { default: 1080 } },
    }
  },
  computed: {
    mediaOriginUrl() {
      return this.$store.state.mediaOriginUrl
    },
  },
  destroyed() {
    console.log('destroyed');
  },
  mounted() {
    this.videoElement = this.$refs.plyr.player
    // Select all video elements
    const videos = document.querySelectorAll('video');

    // Add event listener to each video
    videos.forEach(video => {
        video.addEventListener('play', function(event) {
            // Pause other videos
            videos.forEach(otherVideo => {
                if (otherVideo !== video) {
                    otherVideo.pause();
                }
            });
        });
    });
    setTimeout(() => {
      this.init()
    }, 2000);
  },
  methods: {
    init() {
      // Video tag => Video.js object
      var player = videojs(this.videoId);
      document.getElementById(this.videoId).style.width = this.width

      // VAST files only, VMAP is not available
      // Bumper ad uses mediafiles from "postroll"
      const prerollAdTag = WhisperAPI.createVastTagUrl('010ab663-b671-1a01-8e5a-95ca31000383', 'MAT');
      const dbi1AdTag = WhisperAPI.createVastTagUrl('010ab663-b671-1a01-8dee-8f0425000276', 'MAT');
      const dbi2AdTag = WhisperAPI.createVastTagUrl('010ab663-b671-1a01-8dee-912c96000277', 'MAT');
      const postrollAdTag = WhisperAPI.createVastTagUrl('010ab663-b671-1a01-8ef1-8ea485000b44', 'MAT');
      // Helpers
      function playPreroll() {
        console.log('playing preroll...');
        player.ima.changeAdTag(prerollAdTag);
        player.ima.requestAds();
      }

      let playedDBI1 = false;
      function playDBI1() {
        console.log('playing dbi-1...');
        player.ima.changeAdTag(dbi1AdTag);
        player.ima.requestAds();
        playedDBI1 = true;
      }

      let playedDBI2 = false;
      function playDBI2() {
        console.log('playing dbi-2...');
        player.ima.changeAdTag(dbi2AdTag);
        player.ima.requestAds();
        playedDBI2 = true;
      }

      let playedBumper = false;
      function playPostroll() {
        console.log('playing bumper...');
        player.ima.changeAdTag(postrollAdTag);
        player.ima.requestAds();
        playedBumper = true;
      }

      // Initializing IMA
      player.ima({
        debug: true,
        id: this.videoId,

        // Some unrelated(?) configs
        disableFlashAds: true,
        showCountdown: false,
        showControlsForJSAds: false,

        // Default VMAP adtag from the example
        // adTagUrl: `http://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=xml_vmap1&unviewed_position_start=1&cust_params=sample_ar%3Dpremidpostpod%26deployment%3Dgmf-js&cmsid=496&vid=short_onecue&correlator=`

        adsManagerLoadedCallback: () => {
          // FIX: Content blink
          player.addClass('bumper-ad');

          // Subscribe to all ads events here

          // This will be called when the preroll ad finished
          // Can't use "COMPLETE" because preroll might include more
          // than 1 ad, which triggers the "COMPLETE" event multiple times
          player.ima.addEventListener(
            window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
            () => {
              console.log('All ads have been played');
              player.removeClass('bumper-ad');

              // This DBI1 will be played after the preroll ad finished
              if (!playedDBI1) playDBI1();

              // Prevent bumper from playing indefinitely
              if (!playedBumper) {
                // This DBI2 will be played before the postroll ad start
                if (!playedDBI2) playDBI2();
                playPostroll();
              }
            }
          );
        },
      });

      // Trigger preroll on first play
      player.one('play', playPreroll);


      // Remove controls from the player on iPad to stop native controls from stealing
      // our click
      var contentPlayer = document.getElementById(`${this.videoId}_html5_api`);
      if (
        (navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/Android/i)) &&
        contentPlayer.hasAttribute('controls')
      ) {
        contentPlayer.removeAttribute('controls');
      }

      // Initialize the ad container when the video player is clicked, but only the
      // first time it's clicked.
      var initAdDisplayContainer = function () {
        player.ima.initializeAdDisplayContainer();
        wrapperDiv.removeEventListener(startEvent, initAdDisplayContainer);
      };

      var startEvent = 'click';
      if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/Android/i)
      ) {
        startEvent = 'touchend';
      }
      var wrapperDiv = document.getElementById(this.videoId);
      wrapperDiv.addEventListener(startEvent, initAdDisplayContainer);
    },
    playPause() {
      if (this.videoElement && this.videoElement.paused) {
        this.videoElement.play()
      } else {
        this.videoElement.pause()
      }
    },
    stopVideo() {
      if(this.videoElement) {
        this.videoElement.stop()
      }
    },
  }
}
