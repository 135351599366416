

export default {
    name: 'jsbanner',
    props: {
        scriptCode: String,
        width: String,
        height: String,
        docId: String,
    },
    mounted () {
        // Render script code using postscribe plugin
        this.$postscribe(`#${this.docId}`, this.scriptCode)
    }
}
