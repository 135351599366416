'use strict'

const VASTTAG_API = `https://ajp.aroscop.com/ads`
const BANNERJSTAG_API = `//js.aroscop.com/img/adcodecdn.js`

let getters = null
class WhisperAPI {
    constructor() {
        getters = getters || this
        return getters
    }

    // Create Video ad Tag URL for Whisper
    createVastTagUrl(site_id, cntid) {
        try {
            const SITEID = `?site-id=${site_id}`;
            const kdua = `&kdua=1`;
            const kdip = `&kdip=1`;
            const fmt = `&fmt=vast`;
            const ver = `&ver=s2s_1`;
            const w = `&w=1920`;
            const h = `&h=1080`;
            const CNTID = `&cntid=${cntid}`;
            const sec = `&sec=1`;
            // const MAXDUR = `&maxdur=${maxdur}`;
            // const MINDUR = `&mindur=${mindur}`;
            // const protocol = `&protocol=2`;
            // const CBUSTER = `&cbuster=${cbuster}`;
            // const language = `&lang=${lang}`;
            // const keywords = `&keywords=sports`;
            // const rewarded = `&rewarded=1`;
            // const skip_video = `&skip_video=1`;
            const gdpr = `&gdpr=0`;

            return `${VASTTAG_API}${SITEID}${kdua}${kdip}${fmt}${ver}${w}${h}${CNTID}${sec}${gdpr}`;
            // return `${VASTTAG_API}${SITEID}${kdua}${kdip}${fmt}${ver}${WIDTH}${HEIGHT}${AID}${FLOOR}${IFA}${LAT}${LON}${SEC}${MAXDUR}${MINDUR}${mimes}${playbk}${protocol}${CBUSTER}${app}${language}${keywords}${rewarded}${skip_video}${GDPR}`;
        } catch (error) {
            throw new Error("Could not build vasttag");
        }
    }

    // Create Banner JS ad Tag for Whisper
    createBannerJSTag(site_id, width, height) {
        try {
            const adcsite = `?adcsite=${site_id}`;
            const dspurl = '&dspurl=aHR0cHM6Ly9hLmFyb3Njb3AuY29tL2ltcGRp';
            const adcw = `&adcw=${width}`;
            const adch = `&adch=${height}`;
            const adceb = '&adceb=1';
            const adcsec = '&adcsec=1';
            const gdpr = '&gdpr=0';

            return `<script type="text/javascript" id="sciera_js_adcode" src="${BANNERJSTAG_API}${adcsite}${dspurl}${adcw}${adch}${adceb}${adcsec}${gdpr}" async defer><\/script>`;
        } catch (error) {
            throw new Error("Could not build bannerjs tag");
        }
    }
}
export default new WhisperAPI()